import { memo, useContext, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import StoreContext from "../context/StoreContext";
import CommonSkeleton from "../components/skeletons/CommonSkeleton";

const AdminPrivateRoute = () => {
  const { fbeUserInfo } = useContext(StoreContext);
  const [isChecking, setIsChecking] = useState(true);
  setTimeout(() => {
    setIsChecking(false);
  }, 100);
  return isChecking ? (
    <CommonSkeleton />
  ) : fbeUserInfo ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export default memo(AdminPrivateRoute);
