import { createContext, useEffect, useState } from "react";
import { getFbeUserDetails } from "../api/fbeLoginApi";
const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  // ----------- LOCAL STORAGE ----------
  const fbeUserInfo = JSON.parse(localStorage.getItem("FBE_USER_INFO"));

  const [storeStates, setStoreStates] = useState({
    fbeUser: null,
    token: fbeUserInfo?.token,
    isLoading: true, // onFirstRefresh
    isError: false, // onFirstRefresh
  });

  useEffect(() => {
    onFirstRefresh();
    // eslint-disable-next-line
  }, []);

  const onFirstRefresh = async () => {
    try {
      if (fbeUserInfo) {
        setStoreStates((p) => ({ ...p, isLoading: true }));
        // get the fbeUser data
        const res = await getFbeUserDetails();
        if (res.isSuccess) {
          setStoreStates((p) => ({
            ...p,
            fbeUser: res.fbeUser,
            isLoading: false,
          }));
        } else {
          setStoreStates((p) => ({ ...p, isError: true }));
        }
      } else {
        setStoreStates((p) => ({ ...p, isLoading: false }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <StoreContext.Provider value={{ fbeUserInfo, storeStates, setStoreStates }}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreContext;
