import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import AuthMiddleware from "../middlewareComponents/AuthMiddleware";
import HomeSkeleton from "../components/skeletons/HomeSkeleton";
import CommonSkeleton from "../components/skeletons/CommonSkeleton";
const Home = lazy(() => import("../pages/HomePage"));
const Login = lazy(() => import("../pages/login/Login"));

const EboRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AuthMiddleware />}>
        <Route
          index
          element={
            <Suspense fallback={<HomeSkeleton />}>
              <Home />
            </Suspense>
          }
        />
        <Route path="/tasks" element={<AuthMiddleware />}>
          <Route
            index
            element={
              <Suspense fallback={<CommonSkeleton />}>
                <div> tasks </div>
              </Suspense>
            }
          />
        </Route>
        <Route path="/history" element={<AuthMiddleware />}>
          <Route
            index
            element={
              <Suspense fallback={<CommonSkeleton />}>
                <div> history </div>
              </Suspense>
            }
          />
        </Route>
        <Route path="/profile" element={<AuthMiddleware />}>
          <Route
            index
            element={
              <Suspense fallback={<CommonSkeleton />}>
                <div> profile </div>
              </Suspense>
            }
          />
        </Route>
        <Route path="/inventory" element={<AuthMiddleware />}>
          <Route
            index
            element={
              <Suspense fallback={<CommonSkeleton />}>
                <div> Inventory </div>
              </Suspense>
            }
          />
        </Route>
        <Route path="/help" element={<AuthMiddleware />}>
          <Route
            index
            element={
              <Suspense fallback={<CommonSkeleton />}>
                <div> help </div>
              </Suspense>
            }
          />
        </Route>
      </Route>

      <Route
        path="/login"
        element={
          <Suspense fallback={<CommonSkeleton />}>
            <Login />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default EboRoutes;
