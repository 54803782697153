import React from "react";

export default function HomeSkeleton() {
  return (
    <div className="flex flex-col px-4">
      <div className="flex  gap-3 mt-4">
        <div className=" w-[4rem] h-[4rem] ebo_skeleton  border bg-blue-50 rounded-full  " />
        <div className="flex gap-2  flex-col pt-2">
          <p className=" flex gap-1 h-[.8125rem] rounded-lg w-[10rem] ebo_skeleton "></p>
          <p className=" flex gap-1 h-[.8125rem] rounded-lg w-[5rem] ebo_skeleton "></p>
        </div>
      </div>
      <div className=" ebo_skeleton h-[5rem] rounded-xl mt-4"></div>
    </div>
  );
}
