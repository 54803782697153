import React from "react";
import { GoHome, GoTasklist } from "react-icons/go";
import { useLocation, useNavigate } from "react-router-dom";
import { HiOutlineUser } from "react-icons/hi2";
import { TbBuildingWarehouse } from "react-icons/tb";
export default function BottomNav() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  if (pathname === "/login") return "";
  return (
    <div
      className=" z-[1] overflow-scroll fixed bottom-0 w-full max-w-[30rem] items-center pb-2
 flex justify-between text-[#171717] bg-white "
    >
      {navLinks.map((curr, idx) => {
        return (
          <div
            key={idx}
            onClick={() => navigate(curr.path)}
            style={{
              color: pathname === curr.path ? "#2136d4" : "black",
              fontWeight: pathname === curr.path ? "600" : "400",
              borderColor: pathname === curr.path ? "#2136d4" : "#e9e9e9",
            }}
            className={`flex border-t flex-1 ${
              pathname === curr.path &&
              " bg-gradient-to-b from-blue-50 to-white "
            }  pt-2 flex-col items-center gap-[2px] text-[22px] cursor-pointer justify-center `}
          >
            {curr.icon}
            <span className="text-[11px]">{curr.lable}</span>
          </div>
        );
      })}
    </div>
  );
}

const navLinks = [
  {
    lable: "Home",
    icon: <GoHome />,
    path: "/",
  },
  {
    lable: "Inventory",
    icon: <TbBuildingWarehouse />,
    path: "/inventory",
  },
  {
    lable: "Tasks",
    icon: <GoTasklist />,
    path: "/tasks",
  },
  {
    lable: "Profile",
    icon: <HiOutlineUser />,
    path: "/profile",
  },
];
