import axios from "axios";
export const generateOTPAPI = async (number) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/fbe/login`, {
      options: {
        data: { number },
        tags: ["otpGenerate"],
      },
    });

    return res.data;
  } catch (error) {
    console.log(error);
    return { isSuccess: false, data: null };
  }
};
export const otpVerifyAPI = async (otp, hashOtp, phoneNumber) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/fbe/login`, {
      options: {
        data: { otp, hashOtp, phoneNumber },
        tags: ["otpVerify"],
      },
    });

    return res.data;
  } catch (error) {
    console.log(error);
    return { isSuccess: false, data: null };
  }
};

export const getFbeUserDetails = async () => {
  try {
    const fbeUserInfo = JSON.parse(localStorage.getItem("FBE_USER_INFO"));

    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/fbe/`,
      {
        options: {
          tags: ["getFbeUserDetails"],
        },
      },
      {
        headers: {
          Authorization: "Bearer " + fbeUserInfo.token,
        },
      }
    );
    if (res.data.invalidToken) {
      localStorage.clear();
      window.location.reload();
    }
    return res.data;
  } catch (error) {
    console.log(error);
    return { isSuccess: false, data: null };
  }
};
