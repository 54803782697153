import React, { memo } from "react";
import EboRoutes from "./EboRoutes";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "../assets/headers/Header";
import BottomNav from "../assets/bottoms/BottomNav";

const EboRouter = () => {
  return (
    <Router>
      <div className=" fixed text-[black] top-0 right-0 left-0 bottom-0 flex justify-center bg-blue-50 ">
        <div
          className={`w-full max-w-[30rem] text-[.875rem] pt-[3.5rem] pb-[4rem] h-full overflow-scroll bg-[white] `}
        >
          <Header />
          <EboRoutes />
          <BottomNav />
        </div>
      </div>
    </Router>
  );
};

export default memo(EboRouter);
