import React from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { GoChecklist } from "react-icons/go";
import { useLocation, useNavigate } from "react-router-dom";

export default function Header() {
  const { pathname } = useLocation();
  const nav = useNavigate();
  if (pathname === "/login") return "";

  return (
    <div className=" w-full max-w-[30rem] z-[1] fixed top-0 bg-white flex justify-between border-b border-gray-200 px-4 pt-2 items-center ">
      <h1
        style={{
          fontFamily: "'Josefin Sans', sans-serif",
        }}
        className="font-[800] text-[#2136d4] text-[1.75rem]"
      >
        ebo
      </h1>
      <div className="flex text-[1.5rem] gap-4">
        <AiOutlineQuestionCircle onClick={() => nav("/help")} />
        <GoChecklist onClick={() => nav("/history")} />
      </div>
    </div>
  );
}
