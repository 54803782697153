import React, { Fragment } from "react";
import "./index.css";
import ErrorBoundaries from "./components/ErrorComponents/ErrorBoundaries";
import ErrorPage from "./components/ErrorComponents/ErrorPage";
// ----------- toast -----------
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EboRouter from "./routes/EboRouter";
// ------------ context provider --------------------
import { StoreProvider } from "./context/StoreContext";

export default function App() {
  return (
    <Fragment>
      <ErrorBoundaries fallback={<ErrorPage />}>
        <StoreProvider>
          <EboRouter />
        </StoreProvider>
      </ErrorBoundaries>
      <ToastContainer limit={1} />
    </Fragment>
  );
}
